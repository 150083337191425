.active {
  border-color: var(--primary-text);
}

body {
  overflow-y: auto;
}

.game .titles p {
  margin: 0;
  border-bottom: 4px solid var(--btn-bg-border);
  padding-bottom: 0.1em;
}

.game .titles,
.grid-display {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  text-align: center;
}

.game .flex:nth-child(1) {
  flex: 0 0;
}

.game .deck {
  margin-top: auto;
}

.game .close-btn {
  position: absolute;
  top: 0;
  right: 0;
}
