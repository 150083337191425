.nav-tabs .nav-link {
  font-weight: 700;
  width: 100% !important;
}

@media screen and (min-width: 321px) and (max-width: 600px) and (max-height: 600px) {
  .nav-tabs .nav-link {
    border: none !important;
    font-size: x-large;
    width: 100% !important;
    padding-left: 50px;
    padding-right: 50px;
    /* color: #f8ae1b !important; */
    color: white !important;
    background-color: #5de0e6 !important;
    /* background-color: #98501d !important; */
  }
}

@media screen and (min-height: 700px) and (max-height: 850px) and (min-width: 400px) and (max-width:500px) {
  .nav-tabs .nav-link {
    border: none !important;
    font-size: x-large;
    width: 100% !important;
    padding-left: 50px!important;
    padding-right: 50px!important;
    /* color: #f8ae1b !important; */
    color: white !important;
    /* background-color: #98501d !important; */
    background-color: #5de0e6 !important;
  }
}

@media screen and (max-width: 320px) {
  .nav-tabs .nav-link {
    border: none !important;
    font-size: x-large;
    width: 100% !important;
    padding-left: 52px;
    padding-right: 52px;
    /* color: #f8ae1b !important; */
    color: white !important;
    background-color: #5de0e6 !important;
    /* background-color: #98501d !important; */
  }
}

.nav-tabs .nav-link.active {
  /* color: #98501d !important; */
  color: white !important;
  background-color: #0467af !important;
  border: none !important;
  font-size: x-large;
  border-top-left-radius: none !important;
  border-top-right-radius: none !important;
}

.nav {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  /* background-image: url("../../images/card.png"); */
}




