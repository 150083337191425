.title-card {
  font-size: var(--fs-800);
  font-weight: var(--fw-700);
  padding: 0.85rem 4rem;
  background-image: url(../../images/title-card.png);
  /* background: var(--btn-bg-primary) center center; */
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  letter-spacing: 1px;
  /* border: 0.275rem solid var(--btn-bg-border);
  border-image: url(../../images/title-card.png) 30 round; */
  border-radius: var(--br);
  /* width: 50%; */
}

.logo-container {
  max-width: 30%;
  margin-left: 35%;
}

@media screen and (max-width: 400px) {
  .title-card {
    font-size: var(--fs-800);
    font-weight: var(--fw-700);
    padding: 0.85rem 4rem;
    background-image: url(../../images/title-card.png);
    /* background: var(--btn-bg-primary) center center; */
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    letter-spacing: 1px;
    /* border: 0.275rem solid var(--btn-bg-border);
    border-image: url(../../images/title-card.png) 30 round; */
    border-radius: var(--br);
    width: 100%;
  }
}

@media screen and (min-width: 401px) and (max-width: 500px) {
 .title-card {
  font-size: var(--fs-800);
  font-weight: var(--fw-700);
  padding: 0.85rem 4rem;
  background-image: url(../../images/title-card.png);
  /* background: var(--btn-bg-primary) center center; */
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  letter-spacing: 1px;
  /* border: 0.275rem solid var(--btn-bg-border);
  border-image: url(../../images/title-card.png) 30 round; */
  border-radius: var(--br);
  width: 100%;
}
}

