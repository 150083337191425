.super-btn:hover {
    background: var(--primary-text);
    color: var(--btn-bg-secondary);
}

.super-btn{
    color: var(--primary-text);
    border: 2px solid var(--primary-text);
}

.super-btn:disabled{
    background: var(--primary-text);
    color: var(--primary-text);
}

.start-btn:disabled {
    background: var(--primary-text);
    color: var(--primary-text);
}

.start-btn {
    background: radial-gradient(circle at 50% 50%, #ffde59, #ff914d) center center/cover no-repeat;
    padding: 10px 20px;
    color: #fff;
    font-weight: bold;
    border-radius: 15px;
    cursor: pointer;
}

.start-btn:hover {
    background: #ff914d;
    color: #fff;
}