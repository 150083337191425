.scores {
  flex-basis: 80%;
  padding: 3rem 2rem;
  overflow-y: scroll;
  font-weight: bold;
  color: white;
  scrollbar-color: white transparent;
  scrollbar-width: thin;
}

.scores::-webkit-scrollbar {
  background: none;
  width: 0.35rem;
}

.scores::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: white;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1rem;
}

th,
td {
  padding: 0.2rem;
  border-right: 0.2rem solid white;
  text-align: center;
}

th:first-child,
td:first-child {
  border-left: 0px;
}

th:last-child,
td:last-child {
  border-right: 0px;
}

tr:first-child td {
  border-top: 0px;
}

/* tr:last-child td {
  border-bottom: 0px;
} */

tr td{
  border: 0.2rem solid white;
  min-width: 60px;
}
.total td{
  border-bottom: 0px;
}

.grid-display.active {
  font-size: 1.5rem;
  background: var(--primary-text);
  color: var(--btn-bg-secondary);
}

.titles th {
  border-bottom: 0.2rem solid white;
}

.row-total {
  background: #e5b299;
  color: var(--btn-bg-secondary);
}

@media screen and (max-width: 700px) {
  .scores {
    padding: 1.5rem 0.1rem;
    font-weight: normal;
    color: white;
    flex-basis: 100%;
  }

  table {
    font-size: 0.75rem;
  }

  .grid-display.active {
    font-size: 1rem;
  }
}
.scores tbody tr:nth-child(even) {
  /* background-color: rgba(249, 249, 249, 0.3); */
}

.scores thead tr th {
  /* background-color: rgba(249, 249, 249, 0.3); */
}