.team-titles-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .flex-wrap {
    flex-wrap: wrap;
  }
  
  .flex-wrap > div {
    grid-template-columns: repeat(2, 1fr);
  }
}



.team-title {
  background: linear-gradient(90deg, #ffde59, #ff914d);
  padding: 10px 10px;
  color: #fff;
  font-weight: bold;
  border-radius: 8px;
  cursor: default;
  text-align: center;
  pointer-events: none;
  width: 10.5rem;
  flex: 0 0 25%; 
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  font-size:medium
}

@media screen and (max-width:424px){
  .team-title{
    width: 8rem;
  }
}
@media screen and (min-width: 425px) and (max-width:600px){
  .team-title{
    width: 10rem;
  }
}

@media screen and (min-width: 601px) and (max-width:767px){
  .team-title{
    width: 9rem;
  }
}

@media screen and (min-width: 768px) and (max-width:1024px){
  .team-title{
    width: 9.5rem;
  }
}
  
@media (max-width: 991px) {
  .team-title {
    flex-basis: 33.33%;
  }
}

@media (max-width: 767px) {
  .team-title {
    flex-basis: 50%;
  }
}


.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  } 

  .game-instruction {
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    position: absolute;
    left: 3%;
    top: 24%;
  }

  .day {
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    position: absolute;
    left: 45%;
    top: 10%;
  }

  .day-logo {
    width: 100px;
    height: auto;
    position: absolute;
    left: 50%;
    top: 2%;
  }

  
  .number {
    position: absolute;
    top: 11%;
    left: 54%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    color: #ffde59;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  }

  .team-label {
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    position: absolute;
    right: 2%;
    top: 2%;
  }

  .team-align {
    position: absolute;
    right: 1%;
    top: 7%;
  }

  .score-card {
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    position: absolute;
    right: 1%;
    top: 17%;
  }

  .money-bag {
    width: 100px;
    height: auto;
    position: absolute;
    right: 1%;
    top: 21%;
  }

  /* .progress-bar {
    width: 150px;
    height: auto;
    left: 50%;
    top: 20%;
  } */

  .fishy-logo {
    width: 240px;
    height: auto;
    position: absolute;
    top: -6%;
  }

  .room-code {
    position: absolute;
    top: 23%;
  }
  
  