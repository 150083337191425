.box {
  background: radial-gradient(circle at 50% 50%, #5de0e6, #0467af) center center/cover no-repeat;
  border: 0.45rem solid transparent;
  border-radius: 30px;
  position: relative;
  width: 16rem;
  height: 17rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box.active {
    border-color: var(--primary-text);
}

@media screen and (max-width:424px){
    .box {
        background: radial-gradient(circle at 50% 50%, #5de0e6, #0467af) center center/cover no-repeat;
        border: 0.45rem solid transparent;
        border-radius: 30px;
        position: relative;
        width: 9rem;
        height: 12rem;
        margin-left: 10px;
        margin-right: 10px;
        flex-grow:1;
      }
    .title-card{
        margin-left:10px;
        margin-right:10px;
    }
}

@media screen and (min-width: 425px) and (max-width:600px){
    .box {
        align-self:center;
        background: radial-gradient(circle at 50% 50%, #5de0e6, #0467af) center center/cover no-repeat;
        border: 0.45rem solid transparent;
        border-radius: 30px;
        position: relative;
        width: 11rem;
        height: 12rem;
        margin-left: 10px;
        margin-right: 10px;
        flex-grow:1;
      }
    .title-card{
        margin-left:10px;
    }
}

@media screen and (min-width: 601px) and (max-width:767px){
    .box {
        align-self:center;
        background: radial-gradient(circle at 50% 50%, #5de0e6, #0467af) center center/cover no-repeat;
        border: 0.45rem solid transparent;
        border-radius: 30px;
        position: relative;
        width: 10rem;
        height: 12rem;
        flex-grow:1;
      }
}

@media screen and (min-width: 768px) and (max-width:1024px){
    .box {
        align-self:center;
        background: radial-gradient(circle at 50% 50%, #5de0e6, #0467af) center center/cover no-repeat;
        border: 0.45rem solid transparent;
        border-radius: 30px;
        position: relative;
        width: 10.5rem;
        height: 12rem;
        flex-grow:1;
      }
}

@media screen and (min-width: 1024px) {
    .box {
        align-self:center;
        background: radial-gradient(circle at 50% 50%, #5de0e6, #0467af) center center/cover no-repeat;
        border: 0.45rem solid transparent;
        border-radius: 30px;
        width: 12.5rem;
        height: 15rem;
        margin-left: 15px;
        margin-right: 15px;
      }
}