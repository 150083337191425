@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

:root {
  --primary-text: white;
  --neutral: #fff;
  --bg-deck-dark: url("./images/Background.png");
  --bg-deck-light: url("./images/Background2.png");
  --btn-bg-primary: url("https://ik.imagekit.io/sjbtmukew5p/Fishy_Equilibrium/bg.png");
  --btn-bg-secondary: #892b03;
  --btn-bg-border: #0467af;
  /* --btn-bg-border: #9a501e; */
  --bg-icon: url("https://ik.imagekit.io/sjbtmukew5p/Fishy_Equilibrium/icon-bg.png");
  --bg-card: url("https://ik.imagekit.io/sjbtmukew5p/Fishy_Equilibrium/card.png");
  --bg-table: url("https://ik.imagekit.io/sjbtmukew5p/Fishy_Equilibrium/table3.png");

  --ff: "Montserrat", sans-serif;

  --fw-400: 400;
  --fw-500: 500;
  --fw-700: 700;

  --fs-300: 1rem;
  --fs-400: 1.25rem;
  --fs-500: 1.375rem;
  --fs-600: 1.4375rem;
  --fs-700: 1.75rem;
  --fs-800: 2rem;

  --br: 1.65rem;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--ff);
  font-size: var(--fs-400);
  font-weight: var(--fw-400);
  background: var(--bg-deck-light) top center/cover no-repeat;
  background-attachment: fixed;
  height: 100vh;
}

/* @media (min-width: 1200px) {
  body {
    background-size: 100% 100%;
  }
} */

