.inp {
  padding: 0.7rem;
  max-width:120px;
  height: 45px;
  border-radius: 20px;
}

.settings .btn {
  color: var(--btn-bg-secondary);
  font-weight: 500;
}

.settings .btn:hover {
  color: var(--btn-bg-secondary);
  background: #fdc740;
}

input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}